<template>
  <div class="common-wrapper">
    <project-tree @select="handleTreeSelect"></project-tree>
    <a-divider />
    <div class="header">
      <div class="header-top">
        <a-range-picker valueFormat="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" class="datepicker"
          :disabled-date="disabledDate" @calendarChange="onCalendarChange" @openChange="onOpenChange"
          v-model:value="searchTimes" @change="onChange" show-time :allowClear="false" />
        <div>
          <a-radio-group v-model:value="alarmType" button-style="solid" style="margin-bottom: 10px">
            <a-badge :count="alarmTotal">
              <a-radio-button value="">全部</a-radio-button>
            </a-badge>
            <a-badge :count="alarmNum[item.value]" v-for="item in AlarmType" :key="item.value">
              <a-radio-button :value="item.value">{{ item.label }}</a-radio-button>
            </a-badge>
          </a-radio-group>
          <a-divider type="vertical" style="margin: 0 10px; height: 30px; border-color: #eee" />
          <a-radio-group v-model:value="totalNumber" button-style="solid" style="margin-bottom: 10px">
            <a-badge :count="readNumber + unReadNumber">
              <a-radio-button value="">全部</a-radio-button>
            </a-badge>
            <a-badge :count="readNumber">
              <a-radio-button :value="true">已确认</a-radio-button>
            </a-badge>
            <a-badge :count="unReadNumber">
              <a-radio-button :value="false">未确认</a-radio-button>
            </a-badge>
          </a-radio-group>
        </div>
      </div>
      <div class="header-bottom">
        <categorySearch style="margin-bottom: 0; min-width: 400px" :source="filterSource" class="searchBlock"
          @change="handleSearch" />
        <a-button @click="handleExport">导出</a-button>
      </div>
    </div>

    <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotAlarmLevel="{ row }">
        <a-tag color="blue" v-if="row.alarmLevel == '00'">{{ row.alarmLevelName }}</a-tag>
        <a-tag color="green" v-else-if="row.alarmLevel == '01'">{{ row.alarmLevelName }}</a-tag>
        <a-tag color="orange" v-else-if="row.alarmLevel == '02'">{{ row.alarmLevelName }}</a-tag>
        <a-tag color="red" v-else>{{ row.alarmLevelName }}</a-tag>
      </template>
    </basic-table>
  </div>

  <a-modal v-model:visible="modalVisible" title="确认事件" @ok="handleOk">
    <div>
      <a-textarea placeholder="请填写确认内容" v-model:value="confirmMessage"></a-textarea>
    </div>
  </a-modal>
  <a-modal v-model:visible="modalVisible2" title="确认事件" okText=" ">
    <a-descriptions>
      <a-descriptions-item :span="3" label="确认人">{{
        currentActionRow.confirmUser
        }}</a-descriptions-item>
      <a-descriptions-item :span="3" label="确认时间">
        {{ dayjs(currentActionRow.confirmTime).format("YYYY-MM-DD HH:mm:ss") }}
      </a-descriptions-item>
      <a-descriptions-item :span="3" label="备注">{{
        currentActionRow.confirmRemark
      }}</a-descriptions-item>
    </a-descriptions>
  </a-modal>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import projectTree from "@/components/business/projectTree/index.vue";
import { BasicTable } from "@/components/basic/table";
import { apiDict } from "@/api/dict";
import _ from "lodash";
import { apiMonitorAlarm } from "@/api/centerMonitor/deviceAlarm.js";
import dayjs from "dayjs";
import { downLoadFile } from "@/utils/excel";
import { message } from "ant-design-vue";
const AlarmType = [
  { label: '通讯告警', value: 'conAlarm' },
  { label: '设备告警', value: 'devAlarm' },
  { label: '规则告警', value: 'ruleAlarm' },
  { label: '服务事件', value: 'serviceEvent' },
  { label: '其它事件', value: 'otherEvent' },
]

const projectIds = ref(null);
const handleTreeSelect = (arrayIds) => {
  projectIds.value = arrayIds
  initCategory();
  initTableData();
};

onMounted(() => {
  initAlarmLevel();
});

const initAlarmLevel = async () => {
  const _list = _.cloneDeep(filterSource.value);
  let res = await apiDict.selective("ALARM_LEVEL");
  _list[1].options = res.result;
  let res2 = await apiDict.selective("ALARM_STATUS");
  _list[3].options = res2.result;
  filterSource.value = _list;
};

const alarmNum = reactive({
  conAlarm: 0,
  devAlarm: 0,
  ruleAlarm: 0,
  serviceEvent: 0,
  otherEvent: 0,
});
const alarmTotal = computed(() => {
  return (
    alarmNum.conAlarm +
    alarmNum.devAlarm +
    alarmNum.ruleAlarm +
    alarmNum.serviceEvent +
    alarmNum.otherEvent
  );
});
const totalNumber = ref("");
watch(
  () => totalNumber.value,
  () => {
    initTableData();
  }
);
const readNumber = ref(0);
const unReadNumber = ref(0);
const initCategory = () => {
  let m = {
    startTime: searchTimes.value[0],
    endTime: searchTimes.value[1],
    projectBizIds: projectIds.value.join(","),
  };
  apiMonitorAlarm.historyTypeNum(m).then(({ result }) => {
    for (const key in alarmNum) {
      alarmNum[key] = 0;
    }
    if (result.length) {
      for (const key in result) {
        let item = result[key];
        alarmNum[item.alarmType] = item.number;
      }
      readNumber.value = _.sumBy(result, "readNumber");
      unReadNumber.value = _.sumBy(result, "unreadNumber");
    } else {
      readNumber.value = 0;
      unReadNumber.value = 0;
    }
  });
};

const alarmType = ref("");
watch(
  () => alarmType.value,
  () => {
    initTableData();
  }
);
const searchForm = ref({});

const filterSource = ref([
  {
    label: "告警名称",
    type: "input",
    bindKey: "objName",
    placeholder: "请输入",
  },
  {
    label: "告警等级",
    type: "select",
    bindKey: "alarmLevel",
    placeholder: "请选择",
    options: [],
  },
  {
    label: "告警码",
    type: "input",
    bindKey: "alarmCode",
    placeholder: "请输入",
  },
  {
    label: "告警状态",
    type: "select",
    bindKey: "alarmStatus",
    placeholder: "请选择",
    options: [],
  },
]);
const tableRef = ref();

const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  scroll: { y: "calc(100vh - 400px)" },
  columns: [
    {
      title: "事件ID",
      type: "text",
      key: "eventId",
    },
    {
      title: "项目",
      type: "text",
      key: "projectName",
    },
    {
      title: "事件类型",
      type: "text",
      key: "alarmTypeName",
    },
    {
      title: "事件对象",
      type: "text",
      key: "objName",
    },
    {
      title: "事件码",
      type: "text",
      key: "alarmCode",
    },
    {
      title: "事件内容",
      type: "text",
      key: "alarmDesc",
    },
    {
      title: "事件等级",
      type: "customize",
      slotName: "slotAlarmLevel",
    },
    {
      title: "事件状态",
      type: "text",
      key: "alarmStatusName",
    },
    {
      title: "事件时间",
      type: "text",
      key: "eventTime",
      formatter(val) {
        return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
      },
    },
  ],
  action: {
    title: "操作",
    width: 100,
    fixed: "right",
    items: [
      {
        icon: "CheckCircleFilled",
        iconTip: "确认",
        type: "icon",
        visibleFn: (row) => !row.isConfirm,
        onClick: (row) => {
          handleConfirm(row);
        },
      },
      {
        icon: "EyeFilled",
        iconTip: "查看",
        type: "icon",
        visibleFn: (row) => row.isConfirm,
        onClick: (row) => {
          handleSee(row);
        },
      },
    ],
  },
});
const searchTimes = ref([
  dayjs().startOf("day").add(-7, "day").format("YYYY-MM-DD HH:mm:ss"),
  dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
]);
let dates = [];
const disabledDate = (current) => {
  if (!dates || dates.length === 0) {
    return false;
  }
  const tooLate = dates[0] && dayjs(current).diff(dates[0], "day") > 30;
  const tooEarly = dates[1] && dayjs(dates[1]).diff(current, "day") > 30;
  return tooEarly || tooLate;
};
const onCalendarChange = (val) => {
  dates = val;
};
const onOpenChange = (open) => {
  if (open) {
    dates = [];
  }
};
const onChange = () => {
  initCategory();
  pageChange(1);
  initTableData();
};

const handleSearch = (form) => {
  searchForm.value = form;
  pageChange(1);
};
const handleExport = () => {
  let param = {
    projectBizIds: projectIds.value.join(","),
    alarmType: alarmType.value,
    ...searchForm.value,
  };
  if (searchTimes.value) {
    param.startTime = searchTimes.value[0];
    param.endTime = searchTimes.value[1];
  }
  if (totalNumber.value === true || totalNumber.value === false) {
    param.isConfirm = totalNumber.value;
  }
  apiMonitorAlarm.historyExport(param).then((res) => {
    const { filename, blob } = res;
    downLoadFile(blob, decodeURI(filename));
    message.success("导出成功");
  });
};

const initTableData = () => {
  let param = {
    pageNo: tableRef.value.paginationProps.current,
    pageSize: tableRef.value.paginationProps.pageSize,
    projectBizIds: projectIds.value.join(","),
    alarmType: alarmType.value,
    ...searchForm.value,
  };
  if (searchTimes.value) {
    param.startTime = searchTimes.value[0];
    param.endTime = searchTimes.value[1];
  }
  if (totalNumber.value === true || totalNumber.value === false) {
    param.isConfirm = totalNumber.value;
  }
  apiMonitorAlarm.historyList(param).then(({ result }) => {
    tableRef.value.dataSourceRef = result.records || [];
    tableRef.value.paginationProps.total = result.total;
  });
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const confirmMessage = ref("");
const modalVisible = ref(false);
const handleOk = () => {
  if (currentActionRow.value.isConfirm) {
    modalVisible.value = false;
    return;
  }
  apiMonitorAlarm
    .actionConfirm({
      eventId: currentActionRow.value.eventId,
      remark: confirmMessage.value,
    })
    .then(() => {
      initTableData();
      modalVisible.value = false;
    });
};
const currentActionRow = ref(null);
const handleConfirm = (row) => {
  currentActionRow.value = row;
  modalVisible.value = true;
  confirmMessage.value = row.confirmRemark;
};

const modalVisible2 = ref(false);

const handleSee = (row) => {
  modalVisible2.value = true;
  currentActionRow.value = row;
};
</script>
<style lang="less" scoped>
.ant-radio-button-wrapper {
  background-color: #fafafa;
}

.ant-divider {
  margin: 20px 0;
}

.header {
  margin-bottom: 12px;

  .header-top {
    display: flex;
    justify-content: space-between;
  }

  .header-bottom {
    margin-top: 12px;

    display: flex;
    justify-content: space-between;

    &>div {
      display: flex;
      gap: 20px;
    }
  }
}

.category-search {
  width: 350px;
}

.dropdownStyle {
  display: inline-block;
  z-index: 10;
  position: relative;
  margin-right: 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #000;

  span {
    margin-right: 5px;
  }

  //     &::before {
  //         position: absolute;
  //         content: '';
  //         width: 100%;
  //         height: 4px;
  //         background: #EBEBEB;
  //         left: 0;
  //         bottom: -10px;
  //     }
}

.datepicker {
  width: 340px;
  margin-right: 10px;
}

.treeStyle {
  position: relative;
  padding: 18px 12px;
  box-shadow: 0 0 2px 2px #d8d8d8;
  background: #fff;
}

.common-wrapper {
  padding-top: 10px;
}

.ant-divider {
  margin-top: 10px;
}

::v-deep .ant-badge-count {
  transform: translate(0, -70%) !important;
  color: #1890FF;
  background-color: #E6F7FF;
}
</style>
